.ImageUploadComponent {
  
  margin-top: 25px;
  margin-bottom: 5px;
  //border: 10px #fff solid;
  padding: 17px;
  position: relative;
  
  .gallery {
    position: fixed;
    left: 0px;
    top: 0px;
    background: white;
    z-index: 199;
    overflow-y: scroll;
    height: 70%;
    margin: 10%;
    padding: 20px;
    border: 2px solid #ccc;
    
    .close {
      position: relative;
      right: 0px;
      top: 0px;
      z-index: 199;
      
      &:after{
        display: inline-block;
        content: "\00d7"; /* This will render the 'X' */
        font-size: 55px;
        line-height: 22px;
      }
    }
    
    
    
    .image-box {
      display: inline-block;
      border: 1px dotted #ccc;
      padding: 3px;
      
      .delete {
        cursor: pointer;
        text-align: center;
        color: #ccc;
        
        &:hover {
          color: red;
          font-weight: bold;
        }
      }
      
      img {
        width: 150px;
        height: 150px;
        cursor: pointer;
        float: left;
      }
    }
  }

  .image-toggle {
    position: absolute;
    left: 15px;
    top: 5px;
  }


  .w-100 {
    width: 100%;
  }
  .float-right {
    float: right;
  }
}

