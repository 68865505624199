@font-face { font-family: MikadoBlack;
    src: url("/css/fonts/MikadoBlack.eot");
    src: url("/css/fonts/MikadoBlack.eot?#iefix") format("embedded-opentype"),
    url("/css/fonts/MikadoBlack.woff2") format("woff2"),
    url("/css/fonts/MikadoBlack.woff") format("woff"),
    url("/css/fonts/MikadoBlack.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

body .panel-heading+.panel-body {
	padding-top: 20px !important;
}
body .k-textbox {
	width: 100%;
}

.title_header {
    padding: 4px 12px;
    margin: 11px 5px 5px 30px;
    font-size: 20px;
    float: left;
}
.signin .content-box {
    background-color: #f3f3f4!important;
    padding: 15px!important;
}
body .k-window,
body .k-window-content {
    border-color: #26a8e0 !important;
	border-radius: 0px !important;
}
.panel-title {
	color:#fff;
}
.panel-heading {
	background-color:#26a8e0 !important;
	border-radius: 0px;
}
.panel-title {
	color:#fff;
}

.panel-body {
	color:#000;
	padding:20px;
}

.panel-default {
	border:none;
}
body .k-widget.k-window .k-window-content *,
body .k-widget.k-window .k-window-content :before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
body .form-group.has-error .validator-message {
    display: inline !important;
}
body .form-group .validator-message {
    display: none;
    color: #a94442;
    font-size: 12px;
}
body .form-group .mandatory:after {
    content: " *";
    color: #a94442;
}
body div.k-window-content {
	padding: 0;
}
body .k-window .panel {
	border-radius: 0px;
}
body .k-window-titleless .panel {
	margin-bottom: 0px; /* scrollbar issue fix */
	background-color: #fff;
	-webkit-box-shadow: none;
	box-shadow: none;
}
body .panel-body .actions {
	text-align: right;
}
body .panel-heading .title {
	font-weight: normal;
	color: #fff;
	font-size: 16px;
	display: inline-block;
	line-height: 33px; /* buttons */
}
body .panel-heading .btn {
	display: inline-block;
	float: right;
	margin-left: 15px;
}
.page-heading .title {
	color: #333;
	font-weight: normal;
	font-size: 16px;
	display: inline-block;
	line-height: 30px;
}
NOTbody .page-heading .btn {
	float: right;
	margin-left: 10px;
}

body .panel-heading {
	padding: 4px 15px;
}

#cropContainerModal1,
#cropContainerModal2,
#cropContainerModal3,
#cropContainerModal4 {
	position: fixed;
}
#croppicModal {
	z-index:11000 !important;
}
#pr_image_container1,
#pr_image_container2,
#pr_image_container3,
#pr_image_container4 {
    position: relative;
    height: 100px;
}
#pr_image_container1 > div,
#pr_image_container2 > div,
#pr_image_container3 > div,
#pr_image_container4 > div {
    float: left;
    margin-right: 10px;
    position: relative;
    width: 100px;
    height: 100px;
    border: 1px solid #e5e6e7;
    overflow: hidden;
    text-align: center;
}

#form-content #pr_image_container1 > div,
#form-content #pr_image_container2 > div,
#form-content #pr_image_container3 > div,
#form-content #pr_image_container4 > div {
    margin-bottom: 10px;
}

#pr_image_container1 i.fa,
#pr_image_container2 i.fa,
#pr_image_container3 i.fa,
#pr_image_container4 i.fa {
    position: absolute;
    top: 37px;
    left: 38px;
    cursor: pointer;
    color: #26a8e0;
    font-size: 24px;
}
#pr_image_container1 i.fa:hover,
#pr_image_container2 i.fa:hover,
#pr_image_container3 i.fa:hover,
#pr_image_container4 i.fa:hover {
    color: #18a689;
}
#pr_image1,
#pr_image2,
#pr_image3,
#pr_image4 {
    display: none;
    position: relative;
    max-height: 100%;
}
.pr_image_container {
	position: relative;
	height: 100px;
}
.pr_image_container > div {
	float: left;
	margin-right: 10px;
	position: relative;
	width: 100px;
	height: 100px;
	border: 1px solid #e5e6e7;
	overflow: hidden;
    text-align: center;
}

#form-content .pr_image_container > div {
	margin-bottom: 10px;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.pr_image_container i.fa {
	position: absolute;
	top: 37px;
	left: 38px;
	cursor: pointer;
	color: #26a8e0;
	font-size: 24px;
}
.pr_image_container i.fa:hover {
	color: #18a689;
}
.pr_image_preview {
	position: relative;
	max-height: 100%;
    max-width: 100%;
}

/* do not create subfolders due to Kendo File/ImageBrowser subdirectory slash is html encoded bug */
.k-filebrowser-toolbar > .k-toolbar-wrap .k-button-icon:nth-child(2) {
	display: none;
}

.row-eq-height {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.dt-bootstrap select.input-sm {
    height: 30px;
    line-height: 14px;
}
body .dd-content,
body .dd-handle	{
	color: #76838f;
}
body .table a {
    text-decoration: none;
}
body .table-row-click tr {
	cursor: pointer;
}
body .page-heading .actions {
	margin-top: 38px;
}
body .dataTable td.sort-order {
	cursor: ns-resize;
}
body .dataTable .sort-order  .fa-arrows-v {
	font-size: 18px;
	opacity: 0.7;
}
body .dataTable td.actions {
	text-align: right;
}
body .dataTable td.actions > * {
	margin: 0 0 0 7px;
}
body .btn-primary.active.focus,
body .btn-primary.active:focus,
body .btn-primary.active:hover,
body .btn-primary:active.focus,
body .btn-primary:active:focus,
body .btn-primary:active:hover,
body .open>.dropdown-toggle.btn-primary.focus,
body .open>.dropdown-toggle.btn-primary:focus,
body .open>.dropdown-toggle.btn-primary:hover {
	background-color: #18a689;
    border-color: #18a689;
    color: #FFFFFF;
}

//body .dataTable td.actions > *:nth-last-child(1) {
//	margin: 0 0 7px !important;
//}
body .inmodal .modal-header {
	padding: 15px 20px;
}
body #eb-confirm {
	.modal-title {
		text-align: center;
	}
	.modal-body {
		text-align: center;
		font-size: 14px;
		padding: 20px;
	}
	.modal-footer {
		text-align: center;
		padding: 20px;
	}

}
body .checkbox.category {
	padding-right: 30px;
}
body #toast-container > .toast-warning:before {
	content: "\f0e7";
	font-family: 'Font Awesome 5 Pro';
}
body #toast-container > .toast-error:before {
	font-family: 'Font Awesome 5 Pro';
	content: "\f071";
}
body #toast-container > .toast-info:before {
	font-family: 'Font Awesome 5 Pro';
	content: "\f005";
}
body #toast-container > .toast-success:before {
	font-family: 'Font Awesome 5 Pro';
	content: "\f00c";
}
body #content-holder .row-eq-height > .col-12 {
	width: 100%;
}

body .table-responsive {
    overflow-x: hidden;
}

body textarea.form-control {
    height: 100px;
}

body .panel .tab-content {
    padding-top: 15px;
}


/* below code is only for page-block edit styling, added to the global styling */
  #window_page_row_template .panel-body #listViewPageRowTemplate {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
  }

  #content-holder .row {
      margin-right: 0 ;
      margin-left: 0;
  }
  .content-block-container {
      position: relative;
      border-width: 1px;
      border-color: transparent;
      border-style: dotted;
  }
  .content-block-container:hover {
      border-color: #26a8e0;
  }
  .content-block-actions {
      display: none;
      position: absolute;
      top: 20%;
      left: 45%;
      z-index: 999;
      background-color: #26a8e0;
      padding: 7px 5px 5px 14px;
  }
  .content-block-container:hover .content-block-actions {
      display: block;
  }
  .content-block-actions i {
      font-size: 22px;
      line-height: 22px;
      color: #fff;
      cursor: pointer;
      display: inline-block;
      margin-right: 10px;
  }
  .content-block-actions i:hover {
      color: #000;
  }
  .content-block-actions i.move {
      cursor: move;
  }
  .content-block-container:hover .content-block {
      opacity: 0.7;
  }

  #cropContainerModal {
      width: 905px;
      height: 450px;
      position:relative;
  }
  #croppicModalObj {
      margin:none !important;
      margin-top:0 !important;
      position: absolute !important;
      top: 50% !important;
      left: 50% !important;
      transform: translate(-50%, -50%) !important;
  }
  .cropImgWrapper {
      -webkit-box-shadow: 10px 10px 25px 2px rgba(0,0,0,0.43);
      -moz-box-shadow: 10px 10px 25px 2px rgba(0,0,0,0.43);
      box-shadow: 2px 2px 25px 2px rgba(0,0,0,0.43);
  }
  .cropControls {
      top:-30px;
  }

  #listView {
      padding: 10px 5px 0px 5px;
      min-height: 200px;
      min-width: 940px;
      border:none;

  }

  .header_image {
      float: left;
      position: relative;
      width: 360px;
      margin: 5px 5px 5px 5px;
      padding: 0;
      cursor: move;
  }

  .header_image img {
      width: 360px;
  }

  #listView .header_image p {
      display:none;
      position: absolute;
      border-radius: 50%;
      background-color:#29abe2;
      border: 3px solid #29abe2;
      width: 30px;
      height: 30px;
      top: 5px;
      right:5px;
      margin: 0;
      padding: 0;
      color:#fff;
      font-size:20px;
      text-align:center;
      line-height:34px;
  }

  #listView .header_image:hover p {
      display:block;
      cursor:pointer;
  }
  .k-listview:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
  }

  .template-8 .pr_image1 > img {
      max-width: 100%;
  }

  .k-header.k-grid-toolbar select.form-control {
      float: right;
      width: 250px;
  }
  .page-row-template {
      border-width: 1px 0 0;
      border-style: solid;
      border-color: #e2e2e2;
      padding: 18px;
      cursor: pointer;
  }
  .page-row-template:hover {
      background-color: #f9f9f9;
  }
  .page-row-template:first-child {
      border-width: 0;
  }
  .page-row-template i {
      float: right;
      display: none;
      font-size: 15px;
      color: #a6a6a6;
  }
  .page-row-template:hover i {
      display: inline-block;
  }
  .content-block-container  {
      margin-bottom: 30px;
  }

  NOT* {
      -webkit-border-radius: 0 !important;
      -moz-border-radius: 0 !important;
      border-radius: 0 !important;
  }

  #addPageRowIndex {
      cursor: pointer;
  }


  .bk-responsive-control {
      color: #fff;
      font-size: 30px;
      line-height: 30px;
      float: right;
      margin-left: 15px;
      cursor: pointer;
  }
  .bk-responsive-control:nth-child(3) {
      margin-right: 50px;
      color: #777;
  }

  .d-lg-none {
      display: none;
  }
  
  /* code up here is only for page-block edit styling, we might delete it later */