.inlined {
	width: auto !important;
	display: inline !important;
}

.btn-sm {
	margin-top: -5px !important;
}

input {
	margin-top: 2px;
}

.BlockKennisbank {
	.block {
		margin-top: 15px;
		background: rgb(246, 246, 246);
		.delete-btn
		{
			position: absolute;
			top:5px;
			right:5px;
			z-index: 9999;
		}
		position: relative;
                padding: 10px 25px;
	}
        
        h4 {
            margin-top: 15px!important;
        }
	/*
	input
	{
		background:#fff;
		border:0px;
		border-bottom:2px solid #ccc;
		font-size:14px;
		padding:3px;
		padding-left:5px;
		padding-right:5px;
		margin:3px;
		color:#000;
	}

	input:disabled
	{
		background-color:rgba(239,239,239,0.3);
		border-color:rgba(118,118,118,0.3);
	}
	*/
}

.block-template-selector
{
	height: 360px;
        padding-top: 20px;
        overflow-y: scroll; 
        width: 100%; 
        display: inline-block; 
        text-align: center; 
        border: 1px solid #ccc;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
            
        > div {
            width: 23%;
            padding: 10px;
            
            &:hover {
                opacity: 1;
                -moz-box-shadow:    3px 3px 5px 2px #ccc;
                -webkit-box-shadow: 3px 3px 5px 2px #ccc;
                box-shadow:         3px 3px 5px 2px #ccc;
                
                .preview {

                }
            }
        }
        
	.preview
	{
		cursor: pointer;
                padding: 0px; 
                width: 100%;
	}
}

.component-block
{
	margin-top:15px;
        border-bottom: 5px solid #ccc;
        padding-bottom:15px;
}

.ButtonComponent, .ImageUploadComponent {
    border-left: 5px solid #ccc;
    padding: 10px;
    margin: 10px 0px;
}