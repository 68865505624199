.eb-action {
    margin-top: 20px;
    text-align: right;
}
.dd-status {
    float: right;
    margin-right: 20px;
    width: 120px;
}
.nestable .text-success {
    color: #46be8a;
}