.abtest
{
    form
    {
        .form-group
        {
            margin-left:0px;
            label
            {
                font-weight:bold;
            }
        }
    }
}