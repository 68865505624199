.pagelist-component{
  padding: 20px;
  background: white;
    
  .page {
    .page-bar {
      background-color: #f3f3f4;
      border: 1px solid #eaeced;
      cursor: pointer;
      color: #9e9e9e;
      margin-bottom: 5px;
      margin-top: 5px;
      padding: 6px;
      
      .page-label {
        width: auto;
        display: inline-block;
        font-weight: bold;
        
        &.type-accountant {
          color: #26a8e0;
        }
        &.type-mkb {
          color: #e84e14;
        }
      }
      
      .tag {
        padding: 0px 5px;
        border-radius: 5px;
        width: auto;
        display: inline-block;
        float: right;
        margin-left: 10px;
        color: white;
      }
      
      .slug {
        background-color: #46ace1;
        
        &.inactive {
          background-color: #ccc;
        }
      }
      
      .redirect {
        background-color: #cd3ee0;
        &.inactive {
          background-color: #ccc;
        }
      }
      
      .canonical {
        background-color: #443ee0;
        &.inactive {
          background-color: #ccc;
        }
      }
    }
    
    .extras {
      display: flex;
      padding-left: 10px;
      
      >div:not(:last-child)
      {
        border-right: 2px solid #ccc;
      }
      
      .url-container {
        width: 33%;
        a {
          color: #46ace1;
        }
      }
      
      .redirects-container {
        width: 33%;
        a {
          color: #cd3ee0;
        }
      }
      
      .canonicals-container {
        width: 33%;
        a {
          color: #443ee0;
        }
      }
    }
  }
}